.login-dropdown{
    position: absolute;
    /* border: 2px red solid; */
    top: 69px;
    left: 40%;
    background-color: var(--nav-color);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 10px 10px 10px;
    color: var(--text-color);
    z-index: 4;
    width: 560px;
    max-height: 200px;
}

.login-form-details{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.email-login-div > input{
    background-color: var(--button-color);
    outline: none;
    border: none;
    border-radius: 3px;
    height: 25px;
    color: var(--text-color);
    font-size: 16px;
}

.password-login-div > input{
    background-color: var(--button-color);
    outline: none;
    border: none;
    border-radius: 3px;
    height: 25px;
    color: var(--text-color);
    font-size: 16px;
}

.email-login-div, .password-login-div  {
    display: flex;
    flex-direction: column;
    height: 25px;
}

.login-button-submit-div{
    display: flex;
    align-items: flex-end;
    gap: 10px;
    padding-top: 18px;
    padding-left: 5px;
}

#login-button-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: none;
    background-color: #17b130;
    color: white;
    padding: 4px;
    font-weight: 700;
    font-size: 16px;
}

#login-button-demo{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: none;
    background-color: rgb(31, 136, 155);
    color: white;
    padding: 4px;
    font-weight: 700;
    font-size: 16px;
}

.errors-login{
    margin: 10px 0 0 0;
    background-color: var(--nav-color);
    padding: 0px 10px 10px 10px
}

.errors-login > p{
    margin: 0;
}
