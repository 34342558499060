.user-profile-page-container{
    display: flex;
    justify-content: center;
    margin: 0px 24px;
}

.user-profile-page{
    margin-top: 20px;
    width: 1000px;
    min-width: 950px;
    max-width: 1000px;
}

.user-profile-info{
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    grid-template-areas:
    "user-img section1 section2";
    min-height: 100px;
    gap: 20px;
}

.user-profile-user-img-container{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: user-img;
}

#user-profile-user-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 70px;
    object-fit: cover;
}

.user-profile-info-section-1{
    grid-area: section1;
    padding-top: 20px;
}

.user-profile-info-section-2{
    grid-area: section2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: flex-end;
}

.user-profile-data-lists{
    grid-area: section2;
    display: flex;
    gap: 40px;
    justify-content: flex-end;
}

.user-profile-data-lists > div > h2{
    font-weight: 800;
    font-size: 24px;
    margin: 0px;
}

.user-profile-data-lists > div > p{
    font-weight: 800;
    font-size: 12px;
    margin: 0px;
    color: var(--secondary-text-color);
}

.user-profile-films-watched-liked{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.user-profile-films-watched-yearly{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.user-profile-lists-created{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.user-profile-edit-button-container{
    display: flex;
    gap: 28px;
    align-items: center;
}

#user-profile-edit-button{
    background-color: var(--button-color);
    color: var(--text-color);
    border: none;
    padding: 6px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 12px;
}

.user-profile-navbar{
    grid-area: profile-nav;
    display: flex;
    justify-content: flex-end;
}

.user-profile-navbar-links{
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
    border-radius: 5px;
}

.user-profile-navbar-links > a{
    display: flex;
    padding: 0px 5px;
    text-decoration: none;
    width: fit-content;
    color: var(--link-color);
    font-size: 16px;
}

.user-profile-navbar-links > a:hover{
    color: rgb(62, 201, 201);
}

.user-profile-navbar-border{
    width: 290px;
    color: var(--secondary-text-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

#profile-selected{
    margin: 0;
}

.user-profile-content-container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "recent-activity list-activity";
    margin: 20px 0px;
    gap: 40px;
}

.user-profile-recent-activity-container{
    grid-area: recent-activity;
}

.user-profile-recent-activity-container > p{
    margin-bottom: 5px;
    border-bottom: 1px var(--border-color) solid;
}

.user-profile-lists-container{
    grid-area: list-activity;

}

.user-profile-recent-films-card{
    max-width: 150px;
    max-height: 250px;
    min-width: 150px;
    min-height: 150px;
}

#user-profile-recent-films-card-img{
    object-fit: cover;
    width: 160px;
    height: 240px;
    border-radius: 5px;
    border: 3px solid var(--text-color);

}

/* object-fit: cover;
height: 335px;
width: 215px;
border: 3px var(--text-color) solid;
border-radius: 5px; */

.user-profile-recent-activity-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
}

.user-profile-recent-films-card > #user-profile-recent-films-card-img:hover {
    border-color: var(--hover-link-color);
}

.user-profile-watchlist-container > p{
    margin-bottom: 5px;
    border-bottom: 1px var(--border-color) solid;
}

.user-profile-watchlist-content{
    margin-top: 10px;
}

.watchlist-image-stack{
    height: 105px;
    display: grid;
    grid-template-columns: repeat( 12, 1fr);
    position: relative;
}

.watchlist-image-1 > img {
    width: 65px;
    height: 105px;

}

.watchlist-image-2 > img {
    width: 65px;
    height: 105px;

}

.watchlist-image-3 > img {
    width: 65px;
    height: 105px;

}

.watchlist-image-4 > img {
    width: 65px;
    height: 105px;

}

#user-profile-list-name{
    margin-top: 5px;
}

.list-img-small{
    border: 2px solid var(--text-color);
}

.list-img-small:hover {
    border-color: var(--hover-link-color);
}
