.delete-profile-modal-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

#delete-profile-text{
    text-align: center;
}

.cancel-delete-profile-button{
    margin-top: 20px;
    background-color: var(--button-color);
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-weight: 600;
    font-family: 'Hanken Grotesk', sans-serif;
}

.delete-cancel-profile-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 20px;
}

.delete-profile-modal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    width: 450px;
    height: 200px;
    margin-top: 300px;
    background-color: var(--background);
}

.create-a-list-button{
    background-color: var(--button-color);
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-weight: 600;
    font-family: 'Hanken Grotesk', sans-serif;
}
