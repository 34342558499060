.review-form-container{
  border-radius: 5px;
  width: 100%;
  height: 17.9em;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  border: 1px solid var(--text-color);
}

.review-form-container > h2{
  text-align: center;
  margin-bottom: 5px;
}

.review-form-review{
  display: flex;
  flex-direction: column;
}

.review-form-review > textarea {
  resize: none;
  height: 110px;
  background-color: var(--button-color);
  border: none;
  outline: none;
  border-radius: 5px;
  color: var(--text-color);
  font-size: 14px;
}

.review-form-review::placeholder{
  font-size: 14px;
}


.review-form-rating{
  display: flex;
  justify-content: center;
}

.review-form-submit{
  display: flex;
  justify-content: center;
}

.review-char-counter > p{
  text-align: end;
  margin: 0;
  font-size: 14px;
}

.button-enabled-review{
  background-color: green;
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  font-weight: 600;
  font-family: 'Hanken Grotesk', sans-serif;
}

.button-enabled-review-delete{
  background-color: rgb(196, 20, 20);
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  font-weight: 600;
  font-family: 'Hanken Grotesk', sans-serif;
}

.button-enabled-review:hover{
  background-color: rgb(5, 158, 5);
}

.review-total-size{
  font-size: 18px;
}

.review-tile-container{
  border-bottom: 1px solid var(--text-color);
  display: flex;
  margin: 20px 0;
}

#review-tile-profile-image{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.review-tile-details{
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-left: 20px;
}

.review-tile-details > p{
  margin: 0;
}

.review-tile-info > p{
  margin-left: 20px;
}

.border-divider-reviews{
  border: 1px solid var(--text-color);
  margin-top: 30px;
}

.review-tile-update-delete-buttons{
  margin-top: 5px;
  display: flex;
  gap: 20px;
}

.delete-review{
  background-color: rgb(196, 20, 20);
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  font-weight: 600;
  font-family: 'Hanken Grotesk', sans-serif;
}
