.members-page-container{
    display: flex;
    justify-content: center;
    margin: 0px 24px;
}

.members-page{
    margin-top: 20px;
    width: 1000px;
    min-width: 950px;
    max-width: 1000px;
}

.members-page-header > h1{
    display: flex;
    justify-content: center;
}

.members-page-header{
    margin-bottom: 50px;
}


.members-page-random-users{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}

/* .members-big-profile-img > img{
    border: 3px solid transparent;
}

.members-big-profile-img > img:hover{
    border-color: var(--hover-link-color);
} */

.members-big-profile-img > img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.members-big-username{
    margin: 10px 0px 0px 0px;
}

.members-big-username > h3{
    color: var(--text-color);
}

.members-big-films-watched > p{
    margin: 0;
    color: var(--secondary-text-color);
}

.members-big-details-tiles{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.members-top-title > p{
    margin: 0;
    color: var(--secondary-text-color);
}

.members-top-title{
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
    padding-bottom: 0;
}

.members-page-user-watches, .members-page-user-views, .members-page-user-lists{
    display: flex;
    align-items: center;
    gap: 10px;
}

.members-page-user-info{
    display: flex;
    gap: 10px;
}

.fa-table-cells-large{
    color: rgb(39, 175, 199);

}

.members-page-user-icons > img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.members-page-user-details{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

}

.members-page-all-users-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

}

.members-page-user-info{
    display: flex;

    justify-content: space-between;

    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 15px;
}

.member-page-title-page{
    border-bottom: 1px solid var(--border-color);
}

.members-page-user-info-panel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
