.footer {
    position: absolute;
    bottom: 0;
    height: 75px;
    color: var(--text-color);;
    background-color: var(--nav-color);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12pt;
    float: left;
}

.social-links a {
    color: var(--text-color);;
    text-decoration: none;
}
