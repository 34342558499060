.create-film-page-container{
    display: flex;
    justify-content: center;
    margin: 0px 24px;
}

.create-film-page{
    margin-top: 20px;
    width: 1000px;
    min-width: 950px;
    max-width: 1000px;
}

.create-film-page > h1{
    margin: 5px;
}

.create-film-form{
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    grid-template-areas: 'left-film-form right-film-form';
}

.left-create-film-form{
    grid-area: left-film-form;
}

.right-create-film-form{
    grid-area: right-film-form;
}

/* LEFT FILM FORM */
.film-form-title-year{
    display: flex;
    gap: 30px;
    margin: 10px 0px 15px;
}

.film-form-title{
    display: flex;
    flex-direction: column;
    width: 350px;
}

.film-form-title > input{
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    height: 20px;
    font-size: 16px;
}

.film-form-year{
    display: flex;
    flex-direction: column;
    width: 150px;
}

.film-form-year > input{
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    height: 20px;
    font-size: 16px;
}

.film-form-genre-director{
    display: flex;
    gap: 30px;
    margin: 10px 0px 15px;
}

.film-form-genre{
    display: flex;
    flex-direction: column;
    width: 250px;
}

.film-form-genre > input{
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    height: 20px;
    font-size: 16px;
}

.film-form-director{
    display: flex;
    flex-direction: column;
    width: 250px;
}

.film-form-director > input{
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    height: 20px;
    font-size: 16px;
}

.film-form-description{
    display: flex;
    flex-direction: column;
    margin: 10px 0px 15px;
}

.film-form-description > textarea{
    resize: none;
    width: 525px;
    height: 120px;
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    font-size: 16px;
}

.film-form-background-image{
    display: flex;
    flex-direction: column;
}

#file-upload-button{
    margin-top: 20px;
    background-color: rgb(31, 136, 155);
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-weight: 600;
    font-family: 'Hanken Grotesk', sans-serif;
}
/* RIGHT FILM FORM */

.film-form-trailer{
    display: flex;
    flex-direction: column;
    margin: 10px 0px 15px;
}

.film-form-trailer > input {
    color: var(--text-color);
    background-color: var(--button-color);
    border: none;
    outline: none;
    height: 20px;
    font-size: 16px;
    margin-bottom: 15px;
}

.trailer-film-preview{
    height: 200px;
    border-radius: 10px;
    border: 1px solid white;

}

.film-form-tile-image{
    display: flex;
    flex-direction: column;
    margin: 10px 0px 15px;
}



#file-upload {
    display: none;
}

.fa-x{
    color: #F765A3;
}

.fa-check{
    color: var(--hover-link-color);
}

/* 333 */
#tile-image-preview {
    margin-top: 10px;
    object-fit: cover;
    width: 250px;
    height: 350px;
    border-radius: 10px;
    border:  1px solid white;
}

/* 529 */
#background-image-preview {
    margin-top: 10px;
    object-fit: cover;
    width: 530px;
    height: 348px;
    border-radius: 10px;
    border:  1px solid white;
}

.file-form-background-image-button{
    border: 1px solid #ccc;
    display: flex;
    /* width: 270px; */
    width: 270px;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 10px;
    gap: 10px;
    /* flex-direction: column; */
    align-items: center;
}

.file-form-background-image-button > i {
    text-align: center;
}

.file-form-tile-image-button{
    border: 1px solid #ccc;
    display: flex;
    width: 225px;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 10px;
    gap: 10px;
    /* flex-direction: column; */
    align-items: center;
}
