.home-page-container{
    display: flex;
    justify-content: center;
}

.home-page{
    width: 100%;
    height: 100%;
}

.home-page-container{

}

.home-img-container{

}

#home-img-preview{
    object-fit: cover;
    width: 101%;
    height: 90vh;
}

/* #home-img{
    width: 1250px;
} */



.footer-border{
    background-color: var(--nav-color);
}

.home-button-signup{
    padding: 13px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: none;
    background-color: #17b130;
    color: white;
    font-weight: 700;
}

.home-text{
    position: absolute;
    top: 400px;
    left: 38%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.home-text > h1{
    margin: 3px;
    color: rgb(229, 229, 230);
    text-shadow: -1.3px -1.3px 0 var(--nav-color), 1.3px -1.3px 0 var(--nav-color), -1.3px 1.3px 0 var(--nav-color), 1.3px 1.3px 0 var(--nav-color);
}

.home-text > button{
    margin-top: 30px;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}

.home-text > button:hover{
    border-color: var(--text-color);
}
