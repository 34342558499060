/* TODO Add site wide styles */
#root {
    --background: #21272E;
    --text-color: #d8d8d8;
    --secondary-text-color: #99AABB;
    --button-color: #44525F;
    --divider-color: #333538;
    --border-color: #333538;
    --hover-link-color: #23ce31;
    --nav-color: #15141B;
    --profile-button-background: var(--secondary-text-color);
    --dropdown-text-color: #282f3a;
    font-family: 'Hanken Grotesk', sans-serif;
}

body{
    background-attachment: fixed;
    background-image: linear-gradient(#181d22, #171b1d33);
    background-color: #21272E;
    color: rgb(201, 197, 197);
    overflow-x: hidden;
}

html{
    margin: 0px -10px;
}

#right-divider{
    border-right: 1px var(--divider-color) solid;
    height: 50px;
    margin-top: 25px;
}

.current-user-profile-section{
    border-bottom: 1px solid var(--hover-link-color);
    display: flex;
    align-items: flex-end;
}

.change-cursor{
    cursor: pointer;
}

.fa-heart{
    color: rgb(221, 121, 27);
}

.fa-eye{
    color: var(--hover-link-color);
}

h3, h4, h5 {
    color: #99AABB;
}

.errors{
    color: #F765A3;
}

.loading-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

@media screen and (max-width: 1024px){
    .login-dropdown{
        left: 31%;
    }

    .signup-dropdown{
        left: 44.5%;
    }

    .profile-dropdown-create{
        top: 60px;
        right: -2px
    }

    /* .home-img-container{
        width: 1045px;
        height: 625px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
    } */

    .home-text{
        top: 400px;
    }

    .footer{
        width: 102%;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {
    .login-dropdown{
        left: 36.5%;
    }

    .films-page-tile{
        width: 223px;
    }

    .films-page-tile > img {
        width: 223px;
    }

    .profile-dropdown-create{
        top: 60px;
        right: 204px;
    }

    /* .home-img-container{
        width: 1459px;
        height: 690px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;

    } */

    .home-text{
        top: 400px;
    }

    .footer{
        width: 101%;
    }
}

@media screen and (min-width: 1441px) {
    .signup-dropdown{
        left: 47%;
    }

    .films-page-tile{
        width: 223px;
    }

    .films-page-tile > img {
        width: 223px;
    }

    .profile-dropdown-create{
        top: 60px;
        right: 452px
    }

    /* .home-img-container{
        width: 1937px;
        height: 830px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        //background-position: bottom 20px;
    } */

    .home-text{
        top: 550px;
    }

    .footer{
        width: 101%;
    }
}
